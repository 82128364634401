/* eslint-disable @typescript-eslint/ban-types */
import 'normalize.css';
import React, { Component, useEffect } from 'react';
import { BrowserRouter, Route, RouteComponentProps, Switch, useLocation } from 'react-router-dom';

import Layout from './Layout';
import PageNotFound from './pages/404';
import Login from './pages/Login';
import Logout from './pages/Logout';
import { FirebaseAnalyticsSession } from './index';
import { logEvent } from 'firebase/analytics';

type State = {};
type Props = RouteComponentProps;

class App extends Component<Props, State> {
  render() {
    return (
      <BrowserRouter>
        <MainLayout>
          <Switch>
            <Route path="/login" exact render={() => <Login {...this.props} />} />
            <Route path="/logout" exact render={() => <Logout {...this.props} />} />
            <Route path="/" component={Layout} />
            <Route component={PageNotFound} />
          </Switch>
        </MainLayout>
      </BrowserRouter>
    );
  }
}

const MainLayout: React.FC = ({ children }) => {
  const location = useLocation();

  useEffect(() => {
    if (FirebaseAnalyticsSession) {
      logEvent(FirebaseAnalyticsSession, 'page_view', {
        page_path: location.pathname,
        page_location: window.location.href,
        page_title: document.title,
        app_type: 'Admin',
      });
      console.log('Logged page view for:', location.pathname);
    }
  }, [location]); // Trigger useEffect when location changes

  return (
    <div>
      {/* Your Layout rendering logic */}
      {children}
    </div>
  );
};
// Use `withRouter` to get access to route props like `location`
export default App;
