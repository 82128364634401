import { AxiosResponse, CancelTokenSource } from 'axios';
import API from './api';

const api = new API();

export type SMSItem = {
  id: number;
  type: number;
  isSent: boolean;
  sentDate: string | null;
};

export default class SmsAPI extends API {
  sendReminderSmsWithPhoneNumberByBookingId(bookingId: number, cancelToken: CancelTokenSource) {
    return this._post(
      `/sms/sendreminder`,
      {
        bookingId,
      },
      cancelToken,
    );
  }

  getSmsHistoryWithBookingMeta(bookingId: number, cancelToken: CancelTokenSource) {
    return this._get(`/sms/getsmshistorywithbookingmeta/${bookingId}`, cancelToken);
  }
}

export const sendCustomSMS: (
  bookingId: number,
  phoneNumber: string,
  message: string,
  cancelToken: CancelTokenSource,
) => Promise<
  AxiosResponse<{
    success: boolean;
    sentDate: string;
    smsList: SMSItem[];
  }>
> = (bookingId, phoneNumber, message, cancelToken) => {
  return api._post(
    '/sms/sendcustomsms',
    {
      bookingId,
      phoneNumber,
      message,
    },
    cancelToken,
  );
};
