import axios from 'axios';
import { initializeApp } from 'firebase/app';
import 'firebase/auth';
import moment from 'moment-timezone';
import React from 'react';
import ReactDOM from 'react-dom';
import RootStore from './stores/RootStore';

import { Provider } from 'mobx-react';
import App from './App';
import { IUser, UserRole } from './stores/UserStore';
import GlobalStyles from './styles/Global';
import SocketUtils from './utils/socket';
import { QueryClient, QueryClientProvider, QueryKey } from 'react-query';
import { getAuth, User } from 'firebase/auth';
import { getAnalytics } from 'firebase/analytics';

moment.tz.setDefault('Atlantic/Reykjavik');

const store = new RootStore();

const firebaseConfig = {
  apiKey: process.env.FIREBASE_API_KEY || '',
  authDomain: `${process.env.FIREBASE_PROJECT_ID || ''}.firebaseapp.com`,
  databaseURL: `https://${process.env.FIREBASE_PROJECT_ID || ''}.firebaseio.com`,
  storageBucket: `${process.env.FIREBASE_PROJECT_ID || ''}.appspot.com`,
  projectId: process.env.FIREBASE_PROJECT_ID || '',
  messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID || '',
  appId: process.env.FIREBASE_APPID,
  measurementId: process.env.FIREBASE_MESURMENT_ID,
};
//const firebaseApp = initializeApp(firebaseConfig);
const firebaseApp = initializeApp(firebaseConfig);
export const FirebaseAuthSession = getAuth(firebaseApp);
export const FirebaseAnalyticsSession = getAnalytics(firebaseApp);
/*
export const AUTH = getAuth(firebaseApp);
if (!firebase.apps.length) {
  // Initialize Firebase

  firebase.initializeApp(firebaseConfig);
}
*/

// Get roles for user
FirebaseAuthSession.onAuthStateChanged((user: User | null) => {
  console.log('user baby', user);

  if (user) {
    console.log('user baby');
    const currentUser = FirebaseAuthSession.currentUser;
    if (!currentUser) {
      throw new Error('No user.');
    }

    currentUser
      .getIdTokenResult()
      .then((idTokenResult) => {
        const {
          restaurant_id: restaurantId,
          role_id: roleId,
          email,
          user_id: firebaseUId,
        } = idTokenResult.claims;

        let userRole: UserRole = UserRole.Staff;
        switch (roleId) {
          case 1:
            userRole = UserRole.Admin;
            break;
          case 2:
            userRole = UserRole.Manager;
            break;
          default:
            userRole = UserRole.Staff;
        }

        const userData: IUser = {
          restaurantId,
          email,
          role: userRole,
          proxyRole: userRole,
          firebaseUId,
        };

        // Init socket if you're manager or staff user
        if (userData && userData.restaurantId) {
          SocketUtils.start(userData.restaurantId);
        }
        store.RestaurantStore.setUser(userData);
      })
      .catch((error: any) => {
        console.log(error);
      });
  } else {
    const url = window.location.href;
    if (!(url.includes('login') || url.includes('logout'))) {
      window.location.href = '/login';
    }
  }
});

function getIdToken(refreshToken: boolean) {
  const currentUser = FirebaseAuthSession.currentUser;
  if (!currentUser) {
    throw new Error('No user.');
  }

  return currentUser
    .getIdToken(refreshToken)
    .then((token: string) => token)
    .catch((error: any) => Promise.reject(error));
}

function getUser() {
  store.UIStore.setIsLoading(true);

  return new Promise((resolve, reject) => {
    FirebaseAuthSession.onAuthStateChanged((user: User | null) => {
      if (user) {
        resolve(user);
      } else {
        window.location.href = '/login';

        reject(Error('It broke'));
      }
    });
  });
}

axios.interceptors.request.use(
  (response) =>
    getUser().then(() =>
      getIdToken(false)
        .then((token) => {
          response.headers!.Authorization = `Bearer ${token}`;
          return response;
        })
        .catch((error) => {
          window.location.href = '/login';

          return Promise.reject(error);
        }),
    ),
  (error) => Promise.reject(error),
);

// Add a response interceptor
axios.interceptors.response.use(
  (response) => {
    store.UIStore.setIsLoading(false);
    return response;
  },
  (error) => {
    console.log(error);
    store.UIStore.setIsLoading(false);

    if (error.response) {
      const { status, data } = error.response;

      // Handle HTTP 3xx status codes or custom redirection logic
      if (status === 423 && data.redirectUrl) {
        window.location.href = data.redirectUrl;
      }
    }
    return Promise.reject(error);
  },
);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      queryFn: async ({ queryKey }: { queryKey: QueryKey }) => {
        const { data } = await axios.get(`${process.env.API_URL}/api/admin/${queryKey[0]}`, {
          params: queryKey[1],
        });
        return data;
      },
    },
  },
});

//
ReactDOM.render(
  <>
    <GlobalStyles />

    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
    </Provider>
  </>,
  document.getElementById('app'),
);
